footer .footer-contacto {
  background: rgba(30, 43, 56, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20%;
  font-size: 1.1em;
  row-gap: 10px;
}

footer div {
  margin: auto;
}

footer .icon-banner1 {
  color: #63c6ae;
  padding-right: 10px;
}

footer .text-banner1 {
  color: var(--color-texto-frase);
  text-decoration: none;
}

footer .footer-copyright-redes-sociales {
  background: #1e2b38;
  padding: 20px 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.contenedor-copyright .copyright {
  line-height: 30px;
  color: #828992;
  text-align: center;
}

.contenedor-copyright .copyright a {
  text-decoration: none;
  color: #c2c2c2;
}

.contenedor-copyright .copyright a:hover {
  color: #63c6ae;
}

.contenedor-redes-sociales a {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: auto;
  color: #c2c2c2;
  border-radius: 3px;
  font-size: 1.2em;
  margin: 0 6px;
  padding: 10px 0;
  width: 45px;
}

.contenedor-redes-sociales .ariba {
  cursor: pointer;
  width: auto;
}

.contenedor-redes-sociales .ariba > img,
.contenedor-redes-sociales .whatsapp > a,
.contenedor-redes-sociales .twitter > a,
.contenedor-redes-sociales .facebook > a,
.contenedor-redes-sociales .linkedin > a {
  vertical-align: middle;
}

.contenedor-redes-sociales .ariba,
.contenedor-redes-sociales .whatsapp,
.contenedor-redes-sociales .twitter,
.contenedor-redes-sociales .facebook,
.contenedor-redes-sociales .linkedin {
  vertical-align: middle;
}

.contenedor-redes-sociales .whatsapp:hover {
  background: #25d366;
  color: #ffffff;
  cursor: pointer;
}

.contenedor-redes-sociales .twitter:hover {
  background: #1da1f2;
  color: #ffffff;
  cursor: pointer;
}

.contenedor-redes-sociales .facebook:hover {
  background: #3b5998;
  color: #ffffff;
  cursor: pointer;
}

.contenedor-redes-sociales .linkedin:hover {
  background: #0077b5;
  color: #ffffff;
  cursor: pointer;
}
