.contenedor-clientes {
  background: var(--color-semiclaro);
  text-align: center;
}

.clientes {
  display: flex;
  position: relative;
  width: 80vw;
  margin: auto;
  overflow: hidden;
  padding-bottom: 20px;
}

.clientes::before,
.clientes::after {
  position: absolute;
  width: var(--logo-width);
  height: 100%;
  content: "";
  z-index: 2;
}

.clientes::before {
  background: linear-gradient(
    to right,
    var(--color-semiclaro),
    rgba(255, 255, 255, 0)
  );
}

.clientes::after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--color-semiclaro)
  );
}

.grupo-logos-empresas {
  display: flex;
  align-items: center;
  justify-content: space-around;
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  width: calc(var(--logo-width) * (var(--total-logos) * 2));
}

/* .grupo-logos-empresas:hover,
.clientes:hover,
.logo-empresa img:hover,
.contenedor-clientes:hover {
  animation-play-state: paused;
} */

.logo-empresa {
  width: var(--logo-width);
}

.logo-empresa img {
  padding: 0 20px 20px 20px;
  width: 100%;
}

@keyframes scroll {
  0% {
    webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    webkit-transform: translateX(
      calc(var(--logo-width-negativo) * var(--total-logos))
    );
    transform: translateX(
      calc(var(--logo-width-negativo) * var(--total-logos))
    );
  }
}
