.contactanos {
  background: var(--color-claro);
  padding: 20px 12% 80px 12%;
  scroll-margin-top: 100px;
}

.contactanos .formulario {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contactanos .formulario input::placeholder,
.contactanos .formulario textarea::placeholder {
  color: var(--color-placeholder);
}

.contactanos .formulario input[type="text"],
.contactanos .formulario input[type="email"],
.contactanos .formulario textarea {
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 20px;
}

.contactanos .formulario input[type="text"],
.contactanos .formulario input[type="email"] {
  width: 49%;
  margin-bottom: 20px;
}

.contactanos .formulario input[type="text"]:focus,
.contactanos .formulario input[type="email"]:focus,
.contactanos .formulario textarea:focus {
  outline: none;
  border: 1px solid var(--color-oscuro);
}

.contactanos .formulario textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 60px;
  resize: none;
}

.contactanos .formulario .boton {
  width: 100%;
  margin: auto;
  background: var(--color-titulo-principal);
  color: var(--color-claro);
  border: none;
  border-radius: 3px;
  padding: 15px 40px;
  cursor: pointer;
  font-size: 1.3rem;
}

.contactanos .formulario .boton:hover {
  opacity: 0.85;
}

.contactanos .formulario .alert {
  width: 100%;
  padding: 1em;
  color: var(--color-claro);
  border-radius: 3px;
  margin-bottom: 40px;
}

.contactanos .formulario .alert,
.error {
  background: #f2dede;
  color: #a94442;
}

.contactanos .formulario .alert.success {
  background: #f8a014;
  opacity: 0.9;
  color: var(--color-claro);
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  position: relative !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  bottom: 25px !important;
  top: 25px !important;
  margin: auto;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
