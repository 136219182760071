.hamburguer {
  display: none;
}

/* ************************************************************* */
/* MEDIA QUERY */
/* ************************************************************* */

@media (max-width: 1024px) {
  .hamburguer {
    display: flex;
    flex-direction: column;
  }

  .hamburguer > span {
    width: 30px;
    height: 4px;
    margin: 3px 0;
    background-color: var(--color-texto-menu);
    border-radius: 2px;
    transform-origin: 5px 0px;
    transition: all 0.3s linear;
  }

  /* Pasa de barras a equis */
  .hamburguer.open > span {
    background-color: var(--color-cierre-menu);
    transform: rotate(45deg) translate(0px, 0px);
  }
  .hamburguer.open > span:nth-child(2) {
    display: none;
  }
  .hamburguer.open > span:nth-child(3) {
    background-color: var(--color-cierre-menu);
    transform: rotate(-45deg) translate(-5px, 1px);
  }
  /* ----------------------- */
}
