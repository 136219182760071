.banner-header {
  height: 75px;
  font-size: 1.1em;
  background: var(--color-oscuro);
  color: #ffffff;
  padding: 0px var(--tamano-margen-header);
  display: grid;
  grid-template-columns: auto auto auto 1fr auto;
  grid-template-areas: "phone email flag empty social";
  align-items: center;
}

@media (max-width: 1024px) {
  .banner-header {
    height: auto;
    grid-template-columns: auto auto;
    grid-template-areas: "phone email" "flag social";
    row-gap: 15px;
    padding: 20px 15px;
    justify-content: center;
    text-align: center;
  }

  .banner-header-flag {
    padding: 0;
  }

  .banner-header-phone {
    text-align: left;
  }

  .banner-header-email {
    text-align: right;
  }
}

@media (max-width: 576px) {
  .banner-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .banner-header-phone,
  .banner-header-email {
    padding: 0;
    text-align: center;
  }
}
