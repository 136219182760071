.banner-fadein {
  animation: fadein var(--segundos-fadein);
}

/* LEFT Banner Header Section */

.banner-header-phone {
  grid-column: phone;
}

.banner-header-email {
  grid-column: email;
  padding-left: 40px;
}

.banner-header-phone > a,
.banner-header-email > a {
  color: var(--color-iconos-banner-header);
  padding-left: 8px;
}

.banner-header-phone > a:hover,
.banner-header-email > a:hover {
  color: var(--color-iconos-banner-header-hover);
  transition: 0.3s;
}

.banner-header-flag {
  grid-column: flag;
  padding-left: 40px;
}

.banner-header-flag > button {
  background-color: var(--background);
  border: 0;
  border-radius: 100px;
}

.flagImg {
  filter: opacity(0.7);
}

.flagImg:hover {
  transition: 0.5s;
  filter: opacity(1);
}

.banner-header-flag img {
  width: 32px;
  vertical-align: text-bottom;
}

/* RIGHT Banner Header Section */

.banner-header-social {
  grid-column: social;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.banner-header-social a {
  color: var(--color-iconos-banner-header);
  display: flex;
  transition: 0.3s;
}

.banner-header-social a:hover {
  color: var(--color-iconos-banner-header-hover);
}

.aribaImg {
  height: 35px;
  vertical-align: middle;
  filter: opacity(0.7);
}
.aribaImg:hover {
  height: 35px;
  vertical-align: middle;
  transition: 0.5s;
  filter: opacity(1);
}
