.navbar-header {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--tamano-margen-header);
  font-size: 1.5em;
  background-color: var(--color-claro);
}

header .header-sticky {
  background-color: var(--color-header-sticky);
  -webkit-box-shadow: 0px 2px 12px 0px rgba(206, 206, 206, 1);
  -moz-box-shadow: 0px 2px 12px 0px rgba(206, 206, 206, 1);
  box-shadow: 0px 2px 12px 0px rgba(206, 206, 206, 1);
}
