.servicios {
  background: var(--color-claro);
  text-align: center;
  scroll-margin-top: 99px;
}

.lista-servicios {
  background-color: var(--color-claro);
  text-align: center;
  scroll-margin-top: 80px;

  background: var(--color-claro);
  text-align: center;
  padding: 0 8%;
  display: flex;
  justify-content: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: var(--color-claro);
}

.cards img {
  max-width: 400px;
  padding-bottom: 30px;
}

.cards img:hover {
  /* max-width: 400px; */
  transform: scale(1.02);
  transition: all 0.5s linear;
  filter: drop-shadow(8px 8px 10px rgb(171, 170, 170));
}

.titulo-servicio {
  color: var(--color-titulo-secundario);
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 30px;
}

.detalle-servicio {
  color: var(--color-texto-descripcion-servicios);
  width: 400px;
  padding-bottom: 40px;
  line-height: 1.5em;
  font-size: 1.2em;
}

@media (max-width: 576px) {
  .cards {
    justify-content: center;
  }

  .cards img,
  .detalle-servicio,
  .titulo-servicio {
    max-width: 360px;
  }
}
