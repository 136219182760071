.chevron {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(2, 73, 89, 0.6);
  color: #fff;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 55px;
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 50;
}

.chevron:hover {
  background: rgba(2, 73, 89, 0.7);
}

.open-chevron {
  height: 65px;
}

@media (max-width: 576px) {
  .chevron {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
}
