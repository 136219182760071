main .frase-main {
  background: var(--color-background-frase);
  color: var(--color-texto-frase);
  padding: 0 var(--tamano-margen-header);
  display: flex;
  justify-content: center;
}

main .frase {
  font-size: 1.7em;
  font-weight: 400;
  padding: 30px 0;
  text-align: center;
}
