.nosotros {
  background: var(--color-semiclaro);
  padding: 0 var(--tamano-margen-header);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  scroll-margin-top: 100px;
}

.nosotros .texto-principal {
  font-size: 1.3em;
  color: var(--color-texto-nosotros);
  line-height: 1.5em;
}

.nosotros .texto-secundario {
  font-size: 1.3em;
  font-weight: 300;
  margin-top: 30px;
  padding-bottom: 60px;
}
