.menu-header {
  display: flex;
  align-items: center;
  animation: fadein var(--segundos-fadein);
}

.menu-header a {
  margin: 0 10px;
  color: var(--color-texto-menu);
  text-decoration: none;
}

.menu-header a:hover {
  color: var(--color-texto-menu-hover);
  padding-bottom: 8px;
  border-bottom: 2px solid var(--color-texto-menu-hover);
  transition: 0.4s linear;
}

/* ************************************************************* */
/* MEDIA QUERIES                                                 */
/* ************************************************************* */

@media (max-width: 1024px) {
  .menu-header {
    position: absolute;
    left: 0;
    top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateX(-100%);
    transition: 0.3s ease all;
  }

  .menu-header.open {
    transform: translateX(0);
  }

  .menu-header a {
    width: 100%;
    text-align: center;
    background-color: var(--color-menu-principal-a);
    line-height: 3em;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .menu-header a:hover {
    border: 0;
    padding: 4px 0;
    /* padding-bottom: 0; */
    color: var(--color-claro);
    background-color: var(--color-menu-principal-a-hover);
  }
}
