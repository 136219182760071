.logo-header {
  width: 320px;
  animation: fadein var(--segundos-fadein);
}

@media (max-width: 576px) {
  .logo-header {
    width: 270px;
  }
}
