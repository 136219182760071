video {
  position: fixed;
  /* filter: blur(4px); */
  z-index: -1;
  /* Centrar la imagen en todo momento*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.diagrama {
  width: 100%;
  height: auto;
  background: var(--color-imagen-opaca);
  color: var(--color-texto-diagrama);
  font-size: 2.1em;
  padding: 0 30px;
}

.diagrama-fadein {
  animation: fadein var(--segundos-fadein);
}

.diagrama-items {
  display: grid;
  grid-template-columns: [left-margin] 1fr [left-text] auto [left-icon] 70px [left-arrow-header] 40px [left-arrow-body] 60px [vertical-bar] 24px [right-arrow-body] 60px [right-arrow-header] 40px [right-icon] 70px [right-text] auto [right-margin] 1fr;
  grid-template-rows: [top-margin] 30px [digitacion] 80px [escaneos] 80px [transcripciones] 80px [desarrollo-web] 80px [desarrollo-software] 80px [asesoria-asistencia] 80px [bottom-margin] 30px;
  align-items: center;
  text-align: center;
}

.diagrama-items a {
  color: var(--color-icono-diagrama);
  text-decoration: none;
}

.diagrama-items .poste {
  grid-column: vertical-bar;
  grid-row: 2/8;
  background: var(--color-diagrama);
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

/* Propiedades comunes de cabezas de Flecha */
.cabeza-flecha-izquierda-digitacion,
.cabeza-flecha-izquierda-transcripciones,
.cabeza-flecha-izquierda-desarrollo-software,
.cabeza-flecha-derecha-escaneos,
.cabeza-flecha-derecha-desarrollo-web,
.cabeza-flecha-derecha-asesoria-asistencia {
  width: 0;
  height: 0;
  margin: 10px 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

/* Propiedades comunes de cabezas de Flecha a la IZQUIERDA */
.cabeza-flecha-izquierda-digitacion,
.cabeza-flecha-izquierda-transcripciones,
.cabeza-flecha-izquierda-desarrollo-software {
  grid-column: left-arrow-header;
  border-right: 40px solid var(--color-diagrama);
}

/* Propiedades comunes de cabezas de Flecha a la DERECHA */
.cabeza-flecha-derecha-escaneos,
.cabeza-flecha-derecha-desarrollo-web,
.cabeza-flecha-derecha-asesoria-asistencia {
  grid-column: right-arrow-header;
  border-left: 40px solid var(--color-diagrama);
}

/* Propiedades comunes de cuerpo de Flecha */
.cuerpo-flecha-digitacion,
.cuerpo-flecha-escaneos,
.cuerpo-flecha-transcripciones,
.cuerpo-flecha-desarrollo-web,
.cuerpo-flecha-desarrollo-software,
.cuerpo-flecha-asesoria-asistencia {
  background: var(--color-diagrama);
  height: 28%;
  width: 100%;
}

/*===== LINEA DIGITACION =====*/

.texto-digitacion {
  grid-column: left-text;
  grid-row: digitacion;
  text-align: right;
}

.texto-digitacion a:hover {
  color: var(--color-icono-diagrama-hover);
}

.icono-digitacion {
  grid-column: left-icon;
  grid-row: digitacion;
}

.icono-digitacion a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-digitacion {
  grid-column: left-arrow-body;
  grid-row: digitacion;
}

.cabeza-flecha-izquierda-digitacion {
  grid-row: digitacion;
}

/*===== LINEA ESCANEOS =====*/

.icono-escaneos {
  grid-column: right-icon;
  grid-row: escaneos;
}

.icono-escaneos a:hover {
  color: var(--color-icono-diagrama-hover);
}

.texto-escaneos {
  grid-column: right-text;
  grid-row: escaneos;
  text-align: left;
}

.texto-escaneos a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-escaneos {
  grid-column: right-arrow-body;
  grid-row: escaneos;
}

.cabeza-flecha-derecha-escaneos {
  grid-row: escaneos;
}

/*===== LINEA TRANSCRIPCIONES =====*/

.texto-transcripciones {
  grid-column: left-text;
  grid-row: transcripciones;
  text-align: right;
}

.texto-transcripciones a:hover {
  color: var(--color-icono-diagrama-hover);
}

.icono-transcripciones {
  grid-column: left-icon;
  grid-row: transcripciones;
}

.icono-transcripciones a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-transcripciones {
  grid-column: left-arrow-body;
  grid-row: transcripciones;
}

.cabeza-flecha-izquierda-transcripciones {
  grid-row: transcripciones;
}

/*===== LINEA DESARROLLO WEB =====*/

.icono-desarrollo-web {
  grid-column: right-icon;
  grid-row: desarrollo-web;
}

.icono-desarrollo-web a:hover {
  color: var(--color-icono-diagrama-hover);
}

.texto-desarrollo-web {
  grid-column: right-text;
  grid-row: desarrollo-web;
  text-align: left;
}

.texto-desarrollo-web a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-desarrollo-web {
  grid-column: right-arrow-body;
  grid-row: desarrollo-web;
}

.cabeza-flecha-derecha-desarrollo-web {
  grid-row: desarrollo-web;
}

/*===== LINEA DESARROLLO SOFTWARE =====*/

.texto-desarrollo-software {
  grid-column: left-text;
  grid-row: desarrollo-software;
  text-align: right;
}

.texto-desarrollo-software a:hover {
  color: var(--color-icono-diagrama-hover);
}

.icono-desarrollo-software {
  grid-column: left-icon;
  grid-row: desarrollo-software;
}

.icono-desarrollo-software a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-desarrollo-software {
  grid-column: left-arrow-body;
  grid-row: desarrollo-software;
}

.cabeza-flecha-izquierda-desarrollo-software {
  grid-row: desarrollo-software;
}

/*===== LINEA ASESORIA Y ASISTENCIA =====*/

.icono-asesoria-asistencia {
  grid-column: right-icon;
  grid-row: asesoria-asistencia;
}

.icono-asesoria-asistencia a:hover {
  color: var(--color-icono-diagrama-hover);
}

.texto-asesoria-asistencia {
  grid-column: right-text;
  grid-row: asesoria-asistencia;
  text-align: left;
}

.texto-asesoria-asistencia a:hover {
  color: var(--color-icono-diagrama-hover);
}

.cuerpo-flecha-asesoria-asistencia {
  grid-column: right-arrow-body;
  grid-row: asesoria-asistencia;
}

.cabeza-flecha-derecha-asesoria-asistencia {
  grid-row: asesoria-asistencia;
}

@media (max-width: 1024px) {
  .diagrama {
    font-size: 1.8em;
  }

  .diagrama-items {
    grid-template-columns: [left-margin] 1fr [left-text] auto [left-icon] 50px [left-arrow-header] 40px [left-arrow-body] 30px [vertical-bar] 20px [right-arrow-body] 30px [right-arrow-header] 40px [right-icon] 50px [right-text] auto [right-margin] 1fr;
  }

  .cabeza-flecha-izquierda-digitacion,
  .cabeza-flecha-izquierda-transcripciones,
  .cabeza-flecha-izquierda-desarrollo-software,
  .cabeza-flecha-derecha-escaneos,
  .cabeza-flecha-derecha-desarrollo-web,
  .cabeza-flecha-derecha-asesoria-asistencia {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  /* Propiedades comunes de cuerpo de Flecha */
  .cuerpo-flecha-digitacion,
  .cuerpo-flecha-escaneos,
  .cuerpo-flecha-transcripciones,
  .cuerpo-flecha-desarrollo-web,
  .cuerpo-flecha-desarrollo-software,
  .cuerpo-flecha-asesoria-asistencia {
    background: var(--color-diagrama);
    height: 20%;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .diagrama {
    font-size: 1.7em;
    padding: 0 30px;
  }

  .diagrama-items {
    grid-template-columns: [left-margin] 1fr [poste] 20px [cuerpo] 25px [cabeza] auto [icono] auto [texto] auto [vacio] 0px [vacio] 0px [vacio] 0px [vacio] 0 [right-margin] 1fr;
  }

  .diagrama-items .poste {
    grid-column: poste;
  }

  .cuerpo-flecha-digitacion,
  .cuerpo-flecha-escaneos,
  .cuerpo-flecha-transcripciones,
  .cuerpo-flecha-desarrollo-web,
  .cuerpo-flecha-desarrollo-software,
  .cuerpo-flecha-asesoria-asistencia {
    grid-column: cuerpo;
  }

  .cabeza-flecha-derecha-escaneos,
  .cabeza-flecha-derecha-desarrollo-web,
  .cabeza-flecha-derecha-asesoria-asistencia {
    grid-column: cabeza;
  }

  .cabeza-flecha-izquierda-digitacion,
  .cabeza-flecha-izquierda-transcripciones,
  .cabeza-flecha-izquierda-desarrollo-software {
    grid-column: cabeza;
    border-left: 40px solid var(--color-diagrama);
    border-right: 0;
  }

  .icono-digitacion,
  .icono-escaneos,
  .icono-transcripciones,
  .icono-desarrollo-software,
  .icono-desarrollo-web,
  .icono-asesoria-asistencia {
    grid-column: icono;
    margin-left: 0.8rem;
  }

  .texto-digitacion,
  .texto-escaneos,
  .texto-transcripciones,
  .texto-desarrollo-software,
  .texto-desarrollo-web,
  .texto-asesoria-asistencia {
    grid-column: texto;
    text-align: left;
    margin-left: 0.8rem;
  }
}
