footer .copyright-redes-sociales {
  background: var(--color-texto-nosotros);
  padding: 20px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

footer .right-part {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  color: #ffffff;
  row-gap: 10px;
}

footer .copy-right {
  color: #828992;
  text-align: center;
  padding-right: 4px;
}

footer .enlace-dep {
  text-decoration: none;
  color: #c2c2c2;
}

footer .enlace-dep:hover {
  color: #63c6ae;
  cursor: auto;
}

/* Iconos de redes sociales */
.footer-header-social {
  width: 100%;

  grid-column: social;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.footer-icon-social {
  width: 40px;
  height: 40px;
  font-size: 0.7em;
  padding: 10px 10px;
  border-radius: 3px;
  color: var(--color-iconos-banner-header);
}

.footer-whatsapp:hover,
.footer-x:hover,
.footer-facebook:hover,
.footer-linkedin:hover {
  color: var(--color-claro);
}

.footer-whatsapp:hover {
  background: #25d366;
}

.footer-x:hover {
  background: #1da1f2;
}

.footer-facebook:hover {
  background: #3b5998;
}

.footer-linkedin:hover {
  background: #0077b5;
}

@media (max-width: 576px) {
  .footer-header-social {
    width: 100%;

    grid-column: social;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
}
