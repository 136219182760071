/* Variables globales: niveles mas altos seudoclases :root->html->body */
:root {
  --color-background: #f2f2f2;
  --color-oscuro: #1e2b38;
  --color-semiclaro: #e2e2e2;
  --color-claro: #ffffff;

  --color-iconos-banner-header: #b9b9b9;
  --color-iconos-banner-header-hover: #ffffff;
  --color-header-sticky: rgba(255, 255, 255, 0.85);

  --color-texto-menu: #828992;
  --color-texto-menu-hover: #1e2b38;
  --color-menu-principal-a: rgba(2, 73, 89, 0.95);
  --color-menu-principal-a-hover: rgba(2, 73, 89, 0.8);
  --color-cierre-menu: #ff0000;
  --color-imagen-opaca: rgba(99, 198, 174, 0.8);

  --color-diagrama: rgba(30, 43, 56, 0.2);
  --color-texto-diagrama: rgba(250, 250, 250, 0.8);
  --color-texto-diagrama-hover: rgba(255, 255, 255, 1);
  --color-icono-diagrama: rgba(255, 255, 255, 0.7);
  --color-icono-diagrama-hover: rgba(250, 250, 250, 1);

  --color-titulo-principal: #63c6ae;
  --color-titulo-secundario: #63c6ae;

  --color-texto-nosotros: #1e2b38;

  --color-background-frase: rgba(30, 43, 56, 0.7);
  --color-texto-frase: #c2c2c2;

  --color-texto-descripcion-servicios: #636363;

  --color-placeholder: #cecece;

  --color-ban1-footer: rgba(30, 43, 56, 0.9);

  --tamano-titulo-principal: 2.6em;
  --tamano-titulo-secundario: 2em;
  --tamano-margen-header: 8%;

  --segundos-fadein: 2.5s;

  --total-logos: 11;
  --logo-width: 200px;
  --logo-width-negativo: -200px;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Oxygen", sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-size: 16px;
  min-width: 360px;
}

.titulo-principal {
  font-size: var(--tamano-titulo-principal);
  font-weight: 300;
  color: var(--color-titulo-principal);
  padding: 60px 10px 60px 10px;
  text-align: center;
}

/* Para que el sticky funcione bien: */
header {
  position: sticky;
  top: 0;
  z-index: 6;
}

/* Para usar con animation en efectos de aparición suave de elementos  */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
